import React, { useContext, useState, useEffect } from 'react';
import * as b from "../components/bootstrap.module.css";
import Icon from "../components/icon.tsx";
import { Link, graphql } from 'gatsby';
import { ComparisionContext } from "../components/comparision-context";
import Scrollbars from "react-custom-scrollbars";
import OutProduct from '../components/out-product';
import Layout from '../components/layout';
import Translate from '../components/translate';

import BaseHead from '../components/base-head';
import siteMetadata from "../utils/site-metadata";
import resolveUrl from '../utils/urls/resolve-url';

export const Head = ({ ...props }) => (
  <BaseHead {...props} title="Comparar productos seleccionados | loando.mx">
    <meta name="robots" content="noindex,nofollow" />
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetadata.domain}/social-media/term/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const Comparison = ({ location: { pathname }, data: { categories }}) => (
  <Layout pathname={pathname}>
    <div className={[b.container, b.py3, b.pyLg4, "comparison-container"].join(" ")}>
      <header>
        <h1 className={["big-title", b.my3, b.mbLg5].join(" ")}>
          <Translate tKey="Compare financial products" />
        </h1>
      </header>

      <HeaderComparison />
      <ComparisionOffers categories={categories} />
    </div>
  </Layout>
);

const HeaderComparison = () => {
  const { comparisionItems } = useContext(ComparisionContext);

  const count = Object.values(comparisionItems).reduce((prev, curr) => prev + curr.length, 0);
  const text = count >= 5 
    ? 'productos financieros' 
    : count >= 2 
      ? 'productos financieros' 
      : 'producto financiero';

  return count > 0 ? (
    <div className={["firm-row","products-chose",b.my3,"comparison-info-box","blackToWhite",].join(" ")}>
      <Icon color="#186CE9" size={25} icon="Info" className={["icon-blog", b.mr2, "iconToWhite"].join(" ")} />
      <Translate tKey="You have chosen" /> {count} {text}
    </div>
  ) : null;
}

const translateApi = {
  'Karty': 'Tarjetas',
  'Konta': 'Cuentas',
};

const ComparisionOffers = ({ categories }) => {
  const { comparisionTabs, fetchComparisionTabs, isLoaded, removeComparisionTabItem } = useContext(ComparisionContext);
  const [activeKey, setActiveKey] = useState('');

  useEffect(() => {
    if (!activeKey) {
      fetchComparisionTabs();
    }
  }, [fetchComparisionTabs, activeKey]);

  useEffect(() => {
    if (comparisionTabs.length && !comparisionTabs.some((tab) => tab.key === activeKey)) {
      setActiveKey(comparisionTabs[0].key);
    }
  }, [comparisionTabs, activeKey]);

  const compareLink = getCompareLink(categories, comparisionTabs, activeKey);

  return (
    <div className={["intro-comparison", b.my4].join(" ")}>
      {isLoaded && comparisionTabs.length > 0 && (
        <div className={["intro-comparison-buttons", b.row, b.mx0, b.mb0].join(" ")}>
          <Scroller className="comparision-scroll">
            {comparisionTabs.map((product, idx) => (
              <div key={`product-${idx}`} className={[b.col7,b.colSm6,b.colMd3,b.colLg2,b.pt2, b.pr2, b.pl0].join(" ")}>
                <ProductButton key={product.key} type={product.key} className={product.key === activeKey ? 'active' : ''} onClick={setActiveKey}>
                  {translateApi[product.label] || product.label}
                </ProductButton>
              </div>
            ))}
          </Scroller>
        </div>
      )}

      <div className={[b.row, b.mt3, b.noGutters].join(' ')}>
        <div className={b.colLg11}>
          {isLoaded && comparisionTabs.length > 0 && (
            <Scroller className="comparision-scroll">
              {comparisionTabs.map((product, idx) => activeKey === product.key && product.items.map((item, sidx) => (
                <div key={`${idx}-${sidx}`} className={[b.colAuto, b.pl0].join(' ')} style={{ minWidth: '280px', maxWidth: '280px' }}>
                  <div className="comparision-product-item">
                    <div className={[b.dFlex, b.alignItemsCenter, b.justifyContentCenter, b.p1].join(' ')} style={{ background: '#ffffff', height: '60px' }}>
                      <img className={b.imgFluid} src={`https://cms.loando.mx/media/${item.image}`} alt={item.name} style={{ height: '100%', maxHeight: '50px' }} loading="lazy"/>
                    </div>

                    <div className={b.mb2} style={{ fontSize: '12px', textAlign: 'center' }}>
                      {item.name}
                    </div>

                    <div className={b.mt2}>
                      <p className={['comparision-product-item-title', b.mb0].join(' ')}><Translate tKey="Rating" /></p>
                      <div className={["stars-container", b.mr2].join(" ")}>
                        <div className="star-box" style={{ width: `${item.rating}% ` }}>
                          <Icon size={20} icon="Star" className={b.mr1} />
                          <Icon size={20} icon="Star" className={b.mr1} />
                          <Icon size={20} icon="Star" className={b.mr1} />
                          <Icon size={20} icon="Star" className={b.mr1} />
                          <Icon size={20} icon="Star" />
                        </div>
                        <div className="empty-star-box">
                          <Icon size={20} icon="Empty-star" className={b.mr1} />
                          <Icon size={20} icon="Empty-star" className={b.mr1} />
                          <Icon size={20} icon="Empty-star" className={b.mr1} />
                          <Icon size={20} icon="Empty-star" className={b.mr1} />
                          <Icon size={20} icon="Empty-star" />
                        </div>
                      </div>
                    </div>

                    <div className={[b.my3, b.py1].join(' ')}>
                      <OutProduct 
                        className={[b.btn,"btn-info","margin-top1","button-256",b.py2,b.fontWeightNormal,b.textWhite,b.justifyContentCenter,b.alignItemsCenter,b.dFlex].join(" ")} 
                        item={item}
                        withIcon={false}
                        defaultDescription={/credits/.test(activeKey) ? 'Apply credit' : 'Apply'}
                      />
                    </div>

                    {/firms|credits/.test(activeKey) ? <FirmOrCredit item={item} /> 
                      : activeKey.startsWith('accounts') ? <Account item={item} /> 
                      : <Card item={item} />}

                    <div className={[b.dFlex, b.flexColumn, b.justifyContentEnd, b.mt4].join(' ')} style={{ flex: '1 1' }}>
                      <OutProduct 
                        className={[b.mb2, b.btn,"btn-info","margin-top1","button-256",b.py2,b.fontWeightNormal,b.textWhite,b.justifyContentCenter,b.alignItemsCenter,b.dFlex].join(" ")} 
                        item={item}
                        withIcon={false}
                        defaultDescription={/credits/.test(activeKey) ? 'Apply credit' : 'Apply'}
                      />

                      <button className={[b.dFlex, b.alignItemsCenter, b.justifyContentCenter, b.btn, "btn-light", b.p2, b.my0, b.w100].join(" ")} onClick={() => removeComparisionTabItem(product.key, Number(item.id))}>
                        <Icon size={20} icon="X" className={b.mr1} />
                        <Translate tKey="Delete" />
                      </button>
                    </div>
                  </div>
                </div>
              )))}
            </Scroller>
          )}
        </div>

        <div className={[b.col1, b.dNone, b.dLgFlex, b.justifyContentEnd, b.pl3].join(' ')} style={{ paddingBottom: '17px' }}>
          <Link to={compareLink ? resolveUrl(compareLink.path, compareLink.term_normalized) : '/'} className={[b.btn, "btn-light", b.p2, b.my0, b.w100].join(" ")} style={{ writingMode: 'vertical-rl', minHeight: '400px', minWidth: '60px', width: '60px', flex: '0 0 60px', fontWeight: 500 }}>
            <Icon color="#2B5087" size={25} icon="Plus-sign" className={[b.mb2, b.mr1].join(' ')} />

            <Translate tKey="Add to compare" />
          </Link>
        </div>

        <div className={[b.col12, b.mt4, b.dLgNone].join(' ')}>
          <Link to={compareLink ? resolveUrl(compareLink.path, compareLink.term_normalized) : '/'} className={[b.btn, "btn-light", b.p2, b.my0, b.w100].join(" ")}>
            <Icon color="#2B5087" size={25} icon="Plus-sign" className={b.mr2} />

            <Translate tKey="Add to compare" />
          </Link>
        </div>
      </div>
    </div>
  );
}

const ProductButton = ({ type, className, children, onClick }) => (
  <button className={className} onClick={() => onClick(type)}>
    {type === "firms1" ? (
      <Icon size={40} icon="Loans" className="icon-blog" />
    ) : type === "credits2" ? (
      <Icon size={40} icon="Cash-loan" className="icon-blog" />
    ) : type === "firms2" ? (
      <Icon size={40} icon="Installment-loan" className="icon-blog"/>
    ) : type === "firms3" ? (
      <Icon size={40} icon="Firm-folder" className="icon-blog"/>
    ) : type === "cards" ? (
      <Icon size={40} icon="Credit-cards" className="icon-blog"/>
    ) : type === "accounts" ? (
      <Icon size={40} icon="Free-account" className="icon-blog"/>
    ) : type === "credits3" ? (
      <Icon size={40} icon="Car-loan" className="icon-blog" />
    ) : type === "credits4" ? (
      <Icon size={40} icon="Consolidation-loan" className="icon-blog"/>
    ) : type === "credits1" ? (
      <Icon size={40} icon="Mortgage" className="icon-blog" />
    ) : null}
    {children}
  </button>
);

const Scroller = ({ children, className }) => (<>
  <Scrollbars
    className={className}
    style={{ margin: '0' }} 
    renderView={({ style, ...props }) => (
      <div style={{ ...style, position: 'static' }} {...props} />
    )}
  >
    <div className={b.dFlex} style={{ marginBottom: '17px' }}>
      {children}
    </div>
  </Scrollbars>
</>);

const FirmOrCredit = ({ item }) => {
  const getClientAge = () => !Number(item.age_min) || !Number(item.age_max) ? '-': <>{Math.min(item.age_min, item.age_max)} - {Math.max(item.age_min, item.age_max)}</>; 
  const getAmountMax = () => item.amountMax > 0 || item.amount_max > 0 ? `${item.amountMax || item.amount_max} $` : '-'; 
  const getTimeMax = () => item.timeMax || item.time_max ? `${item.timeMax || item.time_max} ${item.time_type === 'days' ? 'días' : 'meses'}` : '';
  const getAddress = () => item.address || item.bank_address?.replace(/<p>|<\/p>|<br \/>/gi, '') || (item.address_street ? `${item.address_street || ''} ${item.address_code || ''} ${item.address_no || ''} ${item.address_city || ''}` : '') || '-'
  const getPhone = () => item.phone || item.bank_phone || '-';
  const getEmail = () => item.email || item.bank_email || '-';

  return (<>
    <div className={[b.mb2, b.pb1].join(' ')}>
      <p className={['comparision-product-item-title', b.mb0].join(' ')}><Translate tKey="Age of the borrower" /></p>
      <span className="comparision-product-item-value">{getClientAge()}</span>
    </div>

    <div className={[b.mb2, b.pb1].join(' ')}>
      <p className={['comparision-product-item-title', b.mb0].join(' ')}><Translate tKey="Maximum amount of loan" /></p>
      <span className="comparision-product-item-value">{getAmountMax()}</span>
    </div>

    <div className={[b.mb2, b.pb1].join(' ')}>
      <p className={['comparision-product-item-title', b.mb0].join(' ')}><Translate tKey="Maximum period of loan" /></p>
      <span className="comparision-product-item-value">{getTimeMax()}</span>
    </div>

    <div className={[b.mb2, b.pb1].join(' ')}>
      <p className={['comparision-product-item-title', b.mb0].join(' ')}><Translate tKey="Lender" /></p>
      <span className="comparision-product-item-value">{item.name}</span>
    </div>

    <div className={[b.mb2, b.pb1].join(' ')}>
      <p className={['comparision-product-item-title', b.mb0].join(' ')}><Translate tKey="Address" /></p>
      <span className="comparision-product-item-value">{getAddress()}</span>
    </div>

    <div className={[b.mb2, b.pb1].join(' ')}>
      <p className={['comparision-product-item-title', b.mb0].join(' ')}><Translate tKey="Phone" /></p>
      <span className="comparision-product-item-value">{getPhone()}</span>
    </div>

    <div className={[b.mb2, b.pb1].join(' ')}>
      <p className={['comparision-product-item-title', b.mb0].join(' ')}><Translate tKey="E-mail address" /></p>
      <span className="comparision-product-item-value">{getEmail()}</span>
    </div>
  </>);
}

const Account = ({ item }) => (<>
  <div className={[b.mb2, b.pb1].join(' ')}>
    <p className={b.mb0}><Translate tKey="Account fee" /></p>
    <span className="comparision-product-item-value">{item.account_fees || 0} <Translate tKey="cu" /></span>
  </div>
  
  <div className={[b.mb2, b.pb1].join(' ')}>
    <p className={b.mb0}><Translate tKey="Card fee" /></p>
    <span className="comparision-product-item-value">{item.card_fees || 0} <Translate tKey="cu" /></span>
  </div>
  
  <div className={[b.mb2, b.pb1].join(' ')}>
    <p className={b.mb0}><Translate tKey="ATMs" /></p>
    <span className="comparision-product-item-value">{item.payment_bank || 0} <Translate tKey="cu" /></span>
  </div>
  
  <div className={[b.mb2, b.pb1].join(' ')}>
    <p className={b.mb0}><Translate tKey="Mobile payments" /></p>
    <span className="comparision-product-item-value">{item.mobile_payment ? <Translate tKey="Yes" /> : <Translate tKey="No" />}</span>
  </div>

  <div className={[b.mb2, b.pb1].join(' ')}>
    <p className={b.mb0}><Translate tKey="Bonus for creating" /></p>
    <span className="comparision-product-item-value">{item.bonus_creating ? <Translate tKey="Yes" /> : <Translate tKey="No" />}</span>
  </div>
</>)

const Card = ({ item }) => (<>
  <div className={[b.mb2, b.pb1].join(' ')}>
    <p className={b.mb0}><Translate tKey="Card fee" /></p>
    <span className="comparision-product-item-value">{item.card_fee ? <>{item.card_fee} <Translate tKey="cu" /></> : '-'}</span>
  </div>
  
  <div className={[b.mb2, b.pb1].join(' ')}>
    <p className={b.mb0}><Translate tKey="Free period" /></p>
    <span className="comparision-product-item-value">{item.free_period ? <>{item.free_period} <Translate tKey="cu" /></>: '-'}</span>
  </div>
  
  <div className={[b.mb2, b.pb1].join(' ')}>
    <p className={b.mb0}><Translate tKey="Maximum limit" /></p>
    <span className="comparision-product-item-value">{item.limit_max ? <>{item.limit_max}<Translate tKey="cu" /></> : '-'}</span>
  </div>
  
  <div className={[b.mb2, b.pb1].join(' ')}>
    <p className={b.mb0}><Translate tKey="Bonus for creating" /></p>
    <span className="comparision-product-item-value">{item.bonus_creating ? <Translate tKey="Yes" /> : <Translate tKey="No" />}</span>
  </div>
</>);

const getCompareLink = (categories, offers, activeCategory) => {
  switch (activeCategory) {
    case 'cards': {
      const cards = offers.find((category) => category.key === activeCategory);
      const cardKey = cards?.items?.[0].type === "2" ? 'firms-cards' : activeCategory;

      return categories.nodes.find((item) => cardKey === item.cluster);
    }

    case 'accounts': {
      const accounts = offers.find((category) => category.key === activeCategory);
      const accountKey = `accounts_${accounts?.items?.[0].type || 1}`;

      return categories.nodes.find((item) => accountKey === item.cluster);
    }

    default: 
      return categories.nodes.find((item) => item.cluster.replace('_', '') === activeCategory) 
        || categories.nodes.find((item) => item.cluster === 'firms_1');
  }
}

export const query = graphql`
  query ComparisionQuery {
    categories: allMysqlTerms(filter:{cluster: {ne: ""}}) {
      nodes {
        path
        term_normalized
        cluster
      }
    }
  }
`

export default Comparison;
